<template>
    <div class="section">
        <div class="content">
            <h1 class="title">
                Upload media set for
                <span v-if="booking">{{ booking.fullAddress }}</span>
            </h1>
            <p v-if="mediaSet">
                View
                <a :href="galleryUrl + '/' + mediaSet.slug">{{
                    mediaSet.slug
                }}</a>
            </p>
            <div class="columns">
                <div class="column has-text-centered">
                    <h2>Lågupplöst</h2>
                    <FilePond
                        v-if="hasBooking"
                        ref="lowDef"
                        :allow-multiple="true"
                        type="low"
                        name="file"
                        lable-idle="Drop low quality here"
                        accepted-file-types="image/jpeg, image/png, image/jpg, video/*"
                        :server="lowDef"
                        :files="lowDefFiles"
                    />
                </div>
                <div class="column has-text-centered">
                    <h2>Högupplöst</h2>
                    <FilePond
                        v-if="hasBooking"
                        ref="highDef"
                        :allow-multiple="true"
                        name="file"
                        label-idle="Drag & Drop your picture or <span class='filepond--label-action'>Browse</span>"
                        accepted-file-types="image/jpeg, image/png, image/jpg, video/*"
                        :server="highDef"
                        :files="highDefFiles"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

export default {
    name: 'UploadMedia',
    components: {
        FilePond: vueFilePond(
            FilePondPluginFileValidateType,
            FilePondPluginImagePreview
        )
    },
    data() {
        return {
            mediaSet: null,
            ready: false,
            lowDefFiles: [],
            highDefFiles: [],
            galleryApi: process.env.VUE_APP_GALLERY_API_URL,
            galleryUrl: process.env.VUE_APP_GALLERY_URL
        }
    },
    computed: {
        booking() {
            return this.$store.getters['booking/booking']
        },
        hasBooking() {
            return this.$store.getters['booking/hasBooking']
        },
        defaultOptions() {
            return {
                url: this.galleryApi + '/media_objects', // this.booking.mediaSet.url
                method: 'POST',
                withCredentials: false,
                headers: {
                    Authorization: 'Bearer ' + this.booking.mediaSet.token
                },
                revert: async (id, load, error) => {
                    await fetch(this.galleryApi + id, {
                        method: 'DELETE',
                        withCredentials: false,
                        headers: {
                            Authorization:
                                'Bearer ' + this.booking.mediaSet.token
                        }
                    })
                        .then(response => {
                            if (response.status === 204) {
                                load()
                            } else {
                                error(response.data)
                            }
                        })
                        .catch(response => {
                            error(response.data)
                        })
                },
                remove: (source, load, error) => {
                    console.log(source)
                    error('test')
                    load()
                }
            }
        },
        lowDef() {
            return {
                ...this.defaultOptions,
                process: {
                    onload: response => {
                        return JSON.parse(response)['@id']
                    },
                    onerror: this.error,
                    ondata: formData => {
                        formData.append('mediaSet', this.booking.mediaSet.uuid)
                        formData.append('mediaType', 0)
                        return formData
                    }
                }
            }
        },
        highDef() {
            return {
                ...this.defaultOptions,
                process: {
                    onload: response => {
                        return JSON.parse(response)['@id']
                    },
                    onerror: this.error,
                    ondata: formData => {
                        formData.append('mediaSet', this.booking.mediaSet.uuid)
                        formData.append('mediaType', 1)
                        return formData
                    }
                }
            }
        },
        video() {
            return {
                ...this.defaultOptions,
                process: {
                    onload: response => {
                        return JSON.parse(response)['@id']
                    },
                    onerror: this.error,
                    ondata: formData => {
                        formData.append('mediaSet', this.booking.mediaSet.uuid)
                        formData.append('mediaType', 2)
                        return formData
                    }
                }
            }
        }
    },
    methods: {
        error(e) {
            alert(e)
        },
        upload(d) {
            d.append('mediaType', 2)
            return d
        },
        async obtainGalleryToken() {
            if (!this.mediaSet) {
                if (this.hasBooking && this.booking.mediaSet) {
                    await this.$store.dispatch(
                        'mediaset/view',
                        this.booking.mediaSet
                    )
                } else {
                    await this.$store.dispatch('mediaset/create', this.booking)
                }
            }
        },
        async getMediaSet(uuid) {
            let mediaSet = await this.$store.dispatch('mediaset/view', uuid)
            if (!mediaSet) {
                await this.$store.dispatch('mediaset/create', this.booking)
            }
        },
        async getBooking() {
            let booking = await this.$store.dispatch(
                'booking/view',
                this.$route.params.uuid
            )
            // let mediaSet = await this.$store.dispatch('mediaset/view', booking.uuid)
            // this.getMediaSet(booking.uuid)
            // let mediaSet = await this.$store.dispatch('mediaset/view', booking.uuid)
            if (!booking.mediaSet) {
                let mediaSet = await this.$store.dispatch(
                    'mediaset/create',
                    this.booking
                )
                this.mediaSet = mediaSet
            } else {
                this.mediaSet = booking.mediaSet
            }
        }
    },
    created() {
        this.getBooking()
    },
    mounted() {}
}
</script>
